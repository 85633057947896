
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$frontend-primary: mat.define-palette(mat.$indigo-palette);
$frontend-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$frontend-theme: mat.define-light-theme((
  color: (
    primary: $frontend-primary,
    accent: $frontend-accent,
    warn: $frontend-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($frontend-theme);

@mixin respond($breakpoint) {
  @if $breakpoint == $big-desktop-up {
    @media (max-width: 1920px) {  //1800px
      @content
    };
  }

  @if $breakpoint == $medium-desktop-up {
    @media (max-width: 1400px) {  //1600px
      @content
    };
  }

  @if $breakpoint == $tablet-only {
    @media (max-width: 1085px) {   //900px
      @content
    };
  }

  @if $breakpoint == $phone-only {
    @media (max-width: 575px) {   //600px
      @content
    };
  }

  @if $breakpoint == $small-phone-only {
    @media (max-width: 420px) {   //600px
      @content
    };
  }

}

@mixin mobileOnly($initial-display) {
  display: none;

  @include respond($small-phone-only) {
    display: $initial-display;
  }
}
$tablet-only: 100.5rem;
html {
    display: block;
    scroll-behavior: smooth;
}


html,
body {
    padding: 0;
    margin: 0;
    float: none;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@supports (-webkit-overflow-scrolling: touch) {
  input {
    font-size: 16px !important;
  }
}